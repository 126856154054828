import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    usesDepth: Boolean
  }

  static targets = [
    'depth'
  ]

  connect() {
    this.toggle(this.usesDepthValue)
  }

  change(event) {
    const usesDepth = event.target.options[event.target.selectedIndex].dataset.usesDepth == 'true'
    this.toggle(usesDepth)
  }

  toggle(show) {
    if (show) {
      this.depthTarget.classList.remove('hidden')
    } else {
      this.depthTarget.classList.add('hidden')
    }
  }


}